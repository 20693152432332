// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-frontpage-jsx": () => import("./../src/templates/frontpage.jsx" /* webpackChunkName: "component---src-templates-frontpage-jsx" */),
  "component---src-templates-cabin-jsx": () => import("./../src/templates/cabin.jsx" /* webpackChunkName: "component---src-templates-cabin-jsx" */),
  "component---src-templates-sauna-jsx": () => import("./../src/templates/sauna.jsx" /* webpackChunkName: "component---src-templates-sauna-jsx" */),
  "component---src-templates-basic-jsx": () => import("./../src/templates/basic.jsx" /* webpackChunkName: "component---src-templates-basic-jsx" */),
  "component---src-templates-activity-jsx": () => import("./../src/templates/activity.jsx" /* webpackChunkName: "component---src-templates-activity-jsx" */),
  "component---src-templates-payment-jsx": () => import("./../src/templates/payment.jsx" /* webpackChunkName: "component---src-templates-payment-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

